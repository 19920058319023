/*
*******************************************
Template Name: Savvyfinances
Author: Arun Ravi
Date: 21-10-2021
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Savvyfinances, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - FOOTER
05 - MODAL
06 - MEDIA QUERY
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

// =======Background Colors========

$background_color_1:#ffffff;
$background_color_2:#000000;
$background_color_3:#ff5200;
$background_color_4:#001b72;
$background_color_5:#1169c2;
$background_color_6:#ebebeb;
$background_color_7:#008000;
$background_color_8:#076807;
$background_color_9:#000000bf;
$background_color_10:#a4c2f4;


// ======Font Styles===============

$color_1:#000000;
$color_2:#ffffff;
$color_3:#b0afaf;
$color_4:#656565;
$color_5:#236093;
$color_6:#0056b3;


$font_family_1:'Roboto', sans-serif; 


$font_size_1:12px;
$font_size_2:14px;
$font_size_3:15px;
$font_size_4:16px;
$font_size_5:18px;
$font_size_6:20px;
$font_size_7:46px;
$font_size_8:26px;
$font_size_9:32px;
$font_size_10:24px;
$font_size_11:13px;


$font-bold:bold;
$font-normal:normal;
$font-w500:500;


// ======Border Styles===============


// $border_style_1: 1px solid #cac6c6;

$border_color_1:#fff;
$border_color_2:#000;
$border_color_3:#d6d5d5;
$border_color_4:#006200;
$border_color_5:#656565;


/*================================================
02 - Sass Mixins
==================================================*/


// @mixin shadow-1 {
//       -webkit-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
//       -moz-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
//       box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
      
// }

// @mixin shadow-2{
//       -webkit-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
//       -moz-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
//       box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
// }


// @mixin f_style1{
//       color: $color_2;
//       font-size: $font_size_16;
//       font-weight: $font-bold;
// }


/*================================================
03 - BODY
==================================================*/



html {
      scroll-behavior: smooth;
}



body {
      font-family: $font_family_1 !important; 
      font-size: $font_size_2; 
      line-height: 1.5; 

}


header.SVY_HDR_V1_header {
      border-bottom: 1px solid $border_color_3;
      padding:0px 0px 15px;

      p{
            color: $color_3;
            font-size: $font_size_4;
            margin-bottom: 0px;
      }

      .logo{

            img{
                  height: 60px;
            }

            .adv_txt{
                  color: $color_3;
                  font-size: $font_size_4;
                  margin-bottom: 0px !important;
            }
      }

      .hdright_sec{

            .top_btn{

                  padding: 10px 0;
                  color: $color_2 !important;
                  font-size: $font_size_6;
                  width: 80%;
                  border-radius: 10px;
                  font-weight: $font-bold;
                  background: #003c6c;
                  border-bottom: 4px solid #0076b4;
            }
      }
}

section.SVY_HDR_V1_section{

      h1{
            font-size: $font_size_7;
            font-weight: $font-bold;
            margin-top: 10px;
      }


      .banner_txt{
            margin-top: 10px;
            border-bottom: 1px solid $border_color_5;
            padding-bottom: 10px;
            font-weight: $font-bold;
            span{
                  background-color: yellow;
            }
      }

      .banner_image{
            width: 100%;
      }

      h3{
            font-size: $font_size_10;
            font-weight: $font-normal;
      }


      ul{
            padding: 0px 0px 0px 30px;
            li{
                  color: $color_1;
                  font-size: $font_size_5;
                  list-style: none;
                  background: url(/Assets/SVY_HDR_V1/img/tick.png) no-repeat;
                  padding: 0px 0px 9px 40px;
                  background-size: 3%;
            }
      }

      p{
            color: $color_1;
            font-size: $font_size_5;

            a{
                  color: $color_6;
            }
      }


	.banner {
		margin: 0 0 15px;
		.mid-banner {
			background: #223466;
			height: auto;
			padding: 5px;
			margin-bottom: 0px;
			.midinnr{
				background:$background_color_1;
				padding: 0 0 20px 0;
				text-align: center;
				.triangle1 {
					width: 0;
					height: 0;
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-top: 20px solid #223466;
					top: 56px;
					left: 49%;
					position: absolute;
				}
				h2{
					background: #223466;
					font-weight: $font-bold;
					color: $color_2;
					font-size: $font_size_10;
					padding: 13px 5px;
					box-shadow: 0px 1px 7px 0px #484848;
					margin-bottom: 30px;
					border-bottom: none;
				}
				a {
					width: 30%;
                              padding: 6px 10px;
                              font-size: $font_size_5;
					font-weight: $font-bold;
					margin: 2px 1px;
					line-height: 34px;
					color: $color_2 !important;
					background: #0eb5e1;
					border-radius: 18px;
					display: inline-block;
                              border: 3px solid #223466;
                              text-align: center;
					&:hover{
						background: #02237e;
                                    text-decoration: none;
                                    
					}

                              img{
                                    height: 60px;
                              }
				}


                        
			}
		}
	}

      // .maptxt_sec{

      //       background: $background_color_6;
      //       padding: 15px;

      //       h2{
      //             font-size: $font_size_10;
      //             font-weight: $font-bold;
      //             color: $color_5;
      //       }
      // }

      // .map_sec{
      //       text-align: center;
      //       padding: 15px;

      //       img{
      //             width: 40%;
      //       }
      // }

      // .main_ctabtn{
      //       background: $background_color_7;
      //       padding: 15px;
      //       border-radius: 15px;
      //       color: $color_2;
      //       font-size: $font_size_6;
      //       width: 35%;
      //       font-weight: $font-bold;
      //       margin: 20px 0;
      //       box-shadow: 1px 12px 10px -11px #000;

      //       &:hover{
      //             color: $color_2;
      //             background: $background_color_8;
      //       }
      // }
}




/*================================================
04 - FOOTER 
==================================================*/

footer.SVY_HDR_V1_footer{
      background: $background_color_6;
      padding: 15px 0px;

      img{
            height: 60px;
            margin-bottom: 15px;
      }

      p {
            color: $color_1;
            font-size: $font_size_4;
        }

        ul{
            padding: 10px 0px 0px 0px;
            text-align: center;
            margin: 0;
            border-top: 1px solid $border_color_2;

              li{
                  list-style: none;
                  display: inline;
                  padding: 0 10px;
                  border-right: 1px solid $border_color_2;
                  color: $color_1;
                  font-size: $font_size_4;

                  &:last-child{
                        border-right: none;
                  }

                  a{
                        color: $color_1;
                        cursor: pointer;

                        &:hover{
                              color: $color_1;
                              text-decoration: none;
                        }
                  }
              }
        }
}



/*================================================
05 - MODAL 
==================================================*/

.modal{
      background: $background_color_9;
}

.hide {
      display: none;
    }
  
    .show {
      display: block;
    }



/*================================================
06 - MEDIA QUERY 
==================================================*/

$break-ipad-pro: 1024px;

$break-min-ipad: 768px;
$break-max-ipad: 990px;

$break-min-tab: 577px;
$break-max-tab: 767px;

//$min-break-small: 576px;
$break-mob: 576px;

@media screen and (max-width: $break-ipad-pro) {
      header.SVY_HDR_V1_header .hdright_sec .top_btn {

            width: 100%;
      }
}

@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {

      header.SVY_HDR_V1_header{
            .hdright_sec{
                  .top_btn{

                        width: 100%;
                  }
            } 
      } 

      section.SVY_HDR_V1_section{
            .main_ctabtn{
                  width: 50%;
            }

            ul{

                  li{
                        background-size: 5%;
                  }
            }
      } 

      section.SVY_HDR_V1_section .banner .mid-banner .midinnr .triangle1 {
            top: 55px;
            left: 47%;
      }

      section.SVY_HDR_V1_section .banner .mid-banner .midinnr a {
            width: 47%;
      }

}

@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {

      header.SVY_HDR_V1_header{
            .hdright_sec{
                  .top_btn{

                        width: 100%;
                        font-size: $font_size_5;
                  }
            } 
      } 

      section.SVY_HDR_V1_section{
            .map_sec{

                  img{

                        width: 70%;
                  }
            } 

            ul{

                  li{
                        background-size: 6%;
                  }
            }

            .main_ctabtn{
                  width: 60%;
            }
      } 

      section.SVY_HDR_V1_section .banner .mid-banner .midinnr a {
            width: 46%;
            font-size: 14px;
      }

      section.SVY_HDR_V1_section .banner .mid-banner .midinnr .triangle1 {
            top: 56px;
            left: 46%;
      }


}

@media (max-width:576px) {

      header.SVY_HDR_V1_header{
            padding: 0px 0px 10px 0px;

            .logo{
                  img{
                        height: 40px;
                  }

                  .adv_txt{
                        padding-left: 15px;
                  }
            }
            .hdright_sec{
                  padding-left: 0px;
                  .top_btn{

                        width: 100%;
                        font-size: $font_size_11;
                  }
            } 
      } 

      section.SVY_HDR_V1_section{

            h1{
                  font-size: $font_size_9;
            }

            p{
                  font-size: $font_size_4;
            }

            ul{
                  padding: 0px 0px 0px 15px;

                  li{
                        padding: 0px 0px 9px 35px;
                        background-size: 8%;
                        font-size: 16px;
                  }
            }

            .maptxt_sec{
                  h2{
                        font-size: $font_size_10;
                  }
            }
            .map_sec{

                  img{

                        width: 100%;
                  }
            } 

            .main_ctabtn{
                  width: 100%;
            }


            .banner{
                  .mid-banner{
                        .midinnr{
                              h2{
                                    font-size: $font_size_10;
                              }
                              .triangle1{
                                    top: 88px;
                                    left: 45%;
                              }

                              a{
                                    width: 90%;
                              }
                        }
                  } 
            } 
      } 

      footer.SVY_HDR_V1_footer{

            p{
                  font-size: 13px;
            }

            ul{
                  li{
                        font-size: 13px;
                        padding: 0 7px;
                  }
            }
      }

}



@media (max-width:360px) {


      section.SVY_HDR_V1_section .banner .mid-banner .midinnr .triangle1 {
            top: 86px;
            left: 44%;
      }

}


@media (max-width:320px) {

      header.SVY_HDR_V1_header .logo .adv_txt {
            text-align: center;
      }


      section.SVY_HDR_V1_section .banner .mid-banner .midinnr .triangle1 {
            top: 85px;
            left: 44%;
      }

}