.SF_TXH_V1{
/*
*******************************************
Template Name: Savvy Finances
Author: Arun Ravi
Date: 10-09-2021
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Savvy Finances, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - FOOTER
05 - MODAL 
06 - MEDIA QUERY
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

// =======Background Colors========

$background_color_1:#ffffff;
$background_color_2:#000000;
$background_color_3:#ffd900;
$background_color_4:#108bb8;
$background_color_5:#35add9;
$background_color_6:#2c9a43;
$background_color_7:#f5f5f5;
$background_color_8:#3ab54a;
$background_color_9:#2f3996;
$background_color_10:#fbff03;
$background_color_11:#011a70;
$background_color_12:#234ad2;
$background_color_13:#1ade64;


// ======Font Styles===============

$color_0:#000000;
$color_1:#ffffff;
$color_2:#272727;
$color_3:#2E2E2E;
$color_4:#cac6c6;
$color_5:#252525;
$color_6:#337ab7;
$color_7:#52658f;
$color_8:#006daf;
$color_9:#01568a;
$color_10:#303291;
$color_11:#dedcdc;
$color_12:#bdbbbb;
$color_13:#f35863;
$color_14:#4f9a31;
$color_15:#dadada;
$color_16:#333333;
$color_17:#3e3d3d;
$color_18:#264dda;
$color_19:#5d78dc;
$color_20:#6aa84f;
$color_21:#011a70;

$font_family_1:'Roboto', sans-serif; 


$font_size_1:16px;
$font_size_2:34px;
$font_size_3:15px;
$font_size_4:18px;
$font_size_5:25px;
$font_size_6:24px;
$font_size_7:22px;
$font_size_8:17px;
$font_size_9:20px;
$font_size_10:35px;
$font_size_11:8px;
$font_size_12:30px;
$font_size_13:14px;
$font_size_14:21px;
$font_size_15:23px;
$font_size_16:36px;
$font_size_17:26px;


$font-bold:bold;
$font-normal:normal;
$font-w500:500;


// ======Border Styles===============


$border_style_1: 1px solid #cac6c6;
$border_style_2: 5px solid #38b64a;
$border_style_3: 5px solid #011a70;
$border_style_4: 4px solid #2f3995;


$border_color_1:#2E2E2E;
$border_color_2:rgba(0, 0, 0, 0.23);
$border_color_3:#333;
$border_color_4:#dedcdc;
$border_color_5:#000000;
$border_color_6:#bdbbbb;
$border_color_7:#dadada;
$border_color_8:#fff;



/*================================================
02 - Sass Mixins
==================================================*/


@mixin shadow-1 {
      -webkit-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
      -moz-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
      box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
      
}

@mixin shadow-2{
      -webkit-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
      -moz-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
      box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
}


@mixin f_style1{
      color: $color_2;
      font-size: $font_size_16;
      font-weight: $font-bold;
}

@mixin f_style2{
      color: $color_10;
      font-weight: $font-bold;
      font-size: $font_size_5;


}

@mixin f_style3{
      font-size: $font_size_8;
      font-weight: $font-bold;
      color: $color_1 !important;

}

@mixin f_style4{
      color: $color_10;
      font-weight: $font-bold;
      font-size: $font_size_5;
}

@mixin f_style5{
      color: $color_0;
      font-size: $font_size_9;
      font-weight: $font-bold;
}


@mixin f_style6{
      font-size: $font_size_4;
      font-weight: $font-bold;
      color: $color_1;
}

@mixin f_style7{
      color: $color_0;
      font-size: $font_size_10;
      font-weight: $font-bold;

}

@mixin f_style8{
      font-size: $font_size_9;
      font-weight: $font-bold;
      color: $color_1 !important;

}



/*================================================
03 - BODY
==================================================*/



html {
      scroll-behavior: smooth;
}
.btn {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: .25rem;
      background-color: #D3D3D3;
      transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.btn-next {
    background: #269ecc;
    color: #fff;
    width: 100%;
    font-weight: 500;
    font-size: 27px;
    padding: 11px 20px;
    cursor: pointer;
    border: 0px;
    border-radius: 4px;
}
.flasher{
                  padding-bottom: 15px;
                  margin-top: 15px;

                  h2 {
                        
                        color: $color_21;
                        font-weight: $font-bold;
                        font-size: 25px;
                        border-bottom: none;
                        margin-bottom: 15px;
                  }

                  h4 {
                        font-size: $font_size_6;
                        line-height: 45px;
                        font-weight: $font-w500;
                  }

                  p {
                        font-weight: $font-bold;
                        font-size: $font_size_7;
                        text-align: center;
                  }

                  .arow{
                        width: 100%;
                        text-align: center;


                        img{
                              height: 20px;
                              position: relative;
                              bottom: 15px;
                        }
                  }

                  .age_brdr{
                        background: $background_color_1;
                        border: 5px solid #009d4f;
                        padding: 10px;
                        width: 100%;
                  }

                  h6{
                        background-color: #009d4f;
                        width: 100%;
                        color: $color_1;
                        margin-bottom: 0px;
                        font-size: $font_size_14;
                        padding: 15px 10px;
                  }

                  a {
                        background-color: #009d4f;
                        line-height: 47px;
                        color: $color_1 !important;
                        border-radius: 50px;
                        display: inline-block;
                        margin: 4px 2px !important;
                        padding: 0px 20px !important;
                  
                        &:hover {
                              text-decoration: none;
                              background:#067f43;
                        }
                  }

}

//           .anim_ylw {
   
//                     background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
//                     background-size: 400% 400%;
//                     -webkit-animation: Gradient 1s ease infinite;
//                     -moz-animation: Gradient 1s ease infinite;
//                     animation: Gradient 1s ease infinite; 
// }

// .anim_ylw2{
//     -webkit-animation: pulse 2.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     -moz-animation: pulse 2.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     -ms-animation: pulse 2.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     animation: pulse 2.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     box-shadow: 0 0 0 0 #49be0a;
// }

// .anim_ylw2{
//     -webkit-animation: pulse 2.50s infinite cubic-bezier(0.66, 0, 0, 1);
//     -moz-animation: pulse 2.50s infinite cubic-bezier(0.66, 0, 0, 1);
//     -ms-animation: pulse 2.50s infinite cubic-bezier(0.66, 0, 0, 1);
//     animation: pulse 2.50s infinite cubic-bezier(0.66, 0, 0, 1);
//     box-shadow: 0 0 0 0 #49be0a;
// }
// .anim_ylw3{
//     -webkit-animation: pulse 3.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     -moz-animation: pulse 3.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     -ms-animation: pulse 3.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     animation: pulse 3.00s infinite cubic-bezier(0.66, 0, 0, 1);
//     box-shadow: 0 0 0 0 #49be0a;
// }

//   @-webkit-keyframes Gradient {
//                     0% {
//                       background-position: 0% 50%; }
//                     50% {
//                       background-position: 100% 50%; }
//                     100% {
//                       background-position: 0% 50%; } }

//                   @-moz-keyframes Gradient {
//                     0% {
//                       background-position: 0% 50%; }
//                     50% {
//                       background-position: 100% 50%; }
//                     100% {
//                       background-position: 0% 50%; } }

//                   @keyframes Gradient {
//                     0% {
//                       background-position: 0% 50%; }
//                     50% {
//                       background-position: 100% 50%; }
//                     100% {
//                       background-position: 0% 50%; } }


::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color_1 !important;
      opacity: 1;
      /* Firefox */
}

:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color_1 !important;
}

::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color_1 !important;
}

body {
      font-family: $font_family_1 !important; 
      font-size: 14px; 
      line-height: 1.5; 
      color: $color_16;
}

.adv_head {
      p {
            margin: 0px;
            padding: 0px;
            font-size: $font_size_1;
      }
}

header {
      background: $background_color_1;

      .row{
            padding: 0px 15px;
      }


      .logo {
   
            padding: 0px 0px 15px 0px;

            img {
                  
                  height: 60px !important;
                  position: relative;
                  top: 12px;

            }
      }

      .top_bnr{
            padding: 0px 0px 15px 0px;

            img{
                  width: 100%;
            }
      }

      .container {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

}

.content_sec {
      background: $background_color_1;
      padding: 0px 0px 30px 0px;

      h1 {
            
            font-size: 46px;
            font-weight: 600;
            margin-bottom: 20px;
            padding: 15px 0px;
            text-align: left;    
            border-top: 1px solid $border_color_2 !important;             
            border-bottom: 1px solid $border_color_2 !important;
      }

      .sidebar {
            text-align: center;

            .style_siz {
                  margin: 0px auto;
            }
      }

      .sec_left {
            border: $border_style_1;
            margin-bottom: 10px;
            text-align: center;

            h4 {
                  font-size: $font_size_3;
                  font-weight: $font-normal;
                  margin: 8px 0px;
            }
      }

      .mid_sec {

            h2 {
                  font-weight: $font-bold;
                  font-size: $font_size_4;
                  margin-top: 15px;
                  margin-bottom: 15px;
            }

            h3{
                  color: $color_0 !important;
                  font-size: $font_size_1 !important;
                  font-weight: bold !important;
                  text-align: left !important;
            }


            .ylwp {
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: 1px solid $border_color_3;
            }

            .ylbg {
                  background: $background_color_10;
                  font-size: $font_size_1;
                  font-weight: $font-bold;
            }

            .mr_b {

                  img{
                        width: 100%;
                        margin-bottom: 14px;
                  }
            }

            p {
                  color: $color_5;
                  font-size: $font_size_1;
                  line-height: 21px;
                  text-align: left;
                  padding-bottom: 0px;

                  a {
                        text-decoration: underline;
                        color: $color_18;

                        &:hover {
                              color: $color_19;
                        }
                  }
            }

            .banner {
                  padding-bottom: 15px;
                  margin-top: 15px;

                  h2 {
                        
                        color: $color_21;
                        font-weight: $font-bold;
                        font-size: 25px;
                        border-bottom: none;
                        margin-bottom: 15px;
                  }

                  h4 {
                        font-size: $font_size_6;
                        line-height: 45px;
                        font-weight: $font-w500;
                  }

                  p {
                        font-weight: $font-bold;
                        font-size: $font_size_7;
                        text-align: center;
                  }

                  .arow{
                        width: 100%;
                        text-align: center;


                        img{
                              height: 20px;
                              position: relative;
                              bottom: 15px;
                        }
                  }

                  .age_brdr{
                        background: $background_color_1;
                        border: 5px solid #009d4f;
                        padding: 10px;
                        width: 100%;
                  }

                  h6{
                        background-color: #009d4f;
                        width: 100%;
                        color: $color_1;
                        margin-bottom: 0px;
                        font-size: $font_size_14;
                        padding: 15px 10px;
                  }

                  a {
                        background-color: #009d4f;
                        padding: 11px 15px;
                        margin: 10px 2px;
                        line-height: 47px;
                        color: $color_1 !important;
                        border-radius: 50px;
                        -webkit-box-shadow: inset 0px -5px 8px 0px rgba(0,0,0,0.34);
                        -moz-box-shadow: inset 0px -5px 8px 0px rgba(0,0,0,0.34);
                        box-shadow: inset 0px -5px 8px 0px rgba(0,0,0,0.34);
                        display: inline-block;
                        margin: 4px 2px !important;
                        padding: 0px 20px !important;
                        &:hover {
                              text-decoration: none;
                              background:#067f43;
                        }
                  }

            }

            .mapp{

                  h2{

                        color: $color_21;
                        font-weight: $font-bold;
                        font-size: 25px;
                        border-bottom: none;
                        margin-bottom: 15px;     
                  }

                  p {
                        font-weight: $font-bold;
                        font-size: $font_size_17;
                        text-align: center;
                  }

                  .map_image{
                        width: 60%;

                  }


                  .main_btn{
                        font-size: $font_size_4;
                        margin: 15px 0px 20px 0px;
                        font-weight: bold;
                        background: #009d4f;
                        text-transform: uppercase;
                        color: #fff;
                        width: 40%;
                        padding: 20px 15px;

                        &:hover{
                              background:#0fab4a;
                              color: #fff;
                        }
                  }


            }


            h3 {
                  @include f_style5;
                  text-align: left;
                  padding: 5px 0px;
                  margin: 0px;
            }


            .btn_cont {
                  margin: 0px auto 25px;

                  .blue_btn {
                        padding: 15px 80px;
                        @include f_style6;
                        margin: 15px 0px 20px 0px;
                        background:$background_color_4 ;
                        text-transform: uppercase;

                        &:hover {
                              background-color:$background_color_5 ;
                        }
                  }
            }

            .btn_optimize {
                  img {
                        width: 100%;
                        margin-bottom: 15px;
                  }
            }
      }

      .rit_sec{
            img{
                  width: 100%;
            }
      }

      .sidebar {
            display: inline-block;
            vertical-align: top;
      }

      .fixedsticky {
            top: 25px;
            position: -webkit-sticky;
            position: -moz-sticky;
            position: -ms-sticky;
            position: -o-sticky;
            position: sticky;
      }

      .fixedsticky-withoutfixedfixed {
            .fixedsticky-off {
                  position: static;
            }

            .fixedsticky-on {
                  position: fixed;
            }
      }

      .fixed-supported {
            .fixedsticky-off {
                  position: static;
            }

            .fixedsticky-on {
                  position: fixed;
            }
      }

      .fixedsticky-dummy {
            display: none;
      }

      .fixedsticky-on {
            & + .fixedsticky-dummy {
                  display: block;
            }
      }
}

/*================================================
04 - FOOTER 
==================================================*/

footer {
      background: $background_color_9;
      padding: 16px 0;

      img{
            height: 70px;
            margin-bottom: 20px;


      }

      p {
            font-size: $font_size_13;
            color:$color_1;
            font-weight:$font-normal;
            text-align: center;

      }

      ul {
            text-align: center;
            padding: 0;
            

            li {
                  font-size: 14px;
                  color: $color_1;
                  font-weight: $font-normal;
                  list-style: none;
                  display: inline;
                  padding-right:10px;
                  border-right: 1px solid $border_color_8;

                  a {
                        font-size: $font_size_13;
                        font-weight: $font-normal;
                        color: $color_1!important;
                        cursor: pointer;

                        &:hover {
                              color: $color_1 !important;
                              text-decoration: none;
                        }
                  }
            }

            .no-br {
                  border: 0px;
            }
      }
}


.cooki {
      font-size: $font_size_11;
      padding: 6px 0;
      position: fixed;
      z-index: 999;
      bottom: 0;
      border: 0px;
      color: $color_1;
      background: #000;
      float: left;
      width: 100%;
      line-height: 11px;
  
      .cookie_txt{
          font-weight: $font-bold;
          font-size: 10px;
      }
  
      .cookitxt{
          font-weight: $font-bold;
          font-size: 10px;
          
          u{
              color: $color_1 !important;
          }
      }
  
      .clse{
          color: red !important;
          margin-left: 5px; 
          font-size: 12px;
      }
  
  }


/*================================================
05 - MODAL 
==================================================*/

.modal{

      background: rgba(0,0,0,.85)!important;

      .modal-content {
            border: $border_style_4 !important;
      }

      .modal-header {

            background: $background_color_9;
            border-radius: 0!important;

            .close{
                  color: $color_1;

                  &:hover{
                      color: $color_1 !important;  
                  }
            }

            h4{
                  font-size: $font_size_9;
                  color:$color_1;
            }
      }

      .modal-body{
            h3{
                  font-size: 18px;
            }

            ul{
                  li{
                        font-size: 14px;
                  }
            }

            .sub{
                  font-size: 18px;
            }
      }

      .modal-footer{
            background: $background_color_9;
            border: 0;
            padding: 10px 20px!important;
      }
}

.hide {
      display: none;
    }
  
    .show {
      display: block;
    }
      
.footer_style{
  text-align: left;
  font-size: 17px;
  font-weight: 600px;
}






/*================================================
06 - MEDIA QUERY 
==================================================*/



$break-ipad-pro: 1024px;

$break-min-ipad: 768px;
$break-max-ipad: 990px;

$break-min-tab: 577px;
$break-max-tab: 767px;

//$min-break-small: 576px;
$break-mob: 576px;

@media screen and (max-width: $break-ipad-pro) {

      .content_sec{

            h1{
                  font-size: $font_size_12;
            }

            .mid_sec{

                  .banner{

                        h6{
                              font-size:26px;
                        }

                        a{
                              padding: 11px 11px;
                        }
                  }
            } 
      } 
      
       
}

@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {


      header{
            padding: 15px 0px 0px 0px;

            .logo{
                  padding: 0px 0px 10px 0px;

                  img{
                        top: 0px;
                        height: 57px;
                  }
            }

            .top_bnr{
                  padding: 0px 0px 10px 0px;
                  img{
                        width: 100%;
                  }
            }
      }


      .content_sec {
            .mid_sec {
                  .banner {
                        a {
                              padding: 10px 23px;
                        }

                        h4 {
                              line-height: 1.2em;
                              margin-bottom: 12px;
                        }
                  }
            }

            .tab_pad {
                  padding: 0px;

            }
      }

      .rit_sec {
            margin-top: 30px;

            img{
                  width: 75% !important;
            }
      }
}



@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {


      header{
            padding: 0px;

            .logo{
                  padding: 10px 0px;

                  img{
                        top: 0px;
                  }
            }
      }

      .content_sec {

            h1{
                  font-size: $font_size_15;
            }

            .mid_sec {

                  .mob_bnr{
                        h2{
                              color: $color_21 !important;
                              font-size: $font_size_6 !important;
                              margin-top: 0px !important;
                        }
                  }
                  .banner {
                        padding-bottom: 0px;


                        h2 {
                              font-weight: $font-bold;
                              font-size: $font_size_5;
                              padding-bottom: 2px;
                        }

                  }

                  .btn_cont {
                        margin: 0px auto 4px;
                  }

                  .grey_box {
                        p {
                              font-size: $font_size_13;
                        }

                        .bg_gre {
                              h3 {
                                    font-size: $font_size_3 !important;
                              }
                        }
                  }

                  .mapp{

                        .main_btn{
                              width: 60%;
                        }

                        .map_image{
                              width: 85%;
                        }
                  }
            }
      }
}

@media screen and (max-width: $break-mob) {

      header{
            padding: 0px;

            h1 {
                  font-size: $font_size_7;
                  margin-bottom: 10px;
                  padding: 5px 0px;
            }

            .logo{
                  padding:10px 0px;

                  img{
                        height: 40px !important;
                        top: 0px;
                  }
            }
      }

      .content_sec{

            padding: 0px;


            .mid_sec{


                  .mob_bnr{

                        h2{
                              color: $color_21 !important;
                              font-size: $font_size_9 !important;
                              margin-top: 0px !important;
                        }
                  }

                  .banner{

                        h2{
                              font-size: $font_size_9;
                        }

                        p{
                              font-size: $font_size_4;
                        }
                      a{
                              padding: 11px 12px !important;
                              margin: 0 !important;
                      }
                  } 

                  .mapp{
                        padding: 0px;

                        h2{
                              font-size: 23px;
                        } 

                        p{
                              font-size: 23px;
                              line-height: 24px;
                        }

                        .map_image{
                              margin-bottom: 15px;
                              width: 100%;
                        }

                        .main_btn{
                              width: 100%;
                        }
                  }
            } 
      }

      footer{
            padding: 15px;

            ul{
                  margin-bottom: 60px;
            }
      }
}


@media (min-width: 360px) and (max-width: 425px) {    
.flasher{
          a {
                background-color: #009d4f;
                padding: 11px 16px !important;
                display: inline-block;
                margin: 4px 2px !important;
                padding: 0px 20px !important;

            }
    
}
} 

@media (min-width: 992px){
.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.d-lg-block {
      display: block!important;
  }
}

}