/*
*******************************************
Template Name: Savvyfinances
Author: Arun Ravi
Date: 20-10-2021
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for Savvyfinances, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - FOOTER 
05 - MODAL 
06 - MEDIA QUERY

============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/


// =======background colors========

$background_color_1: #fff;
$background_color_2:#f7f7f7;
$background_color_3:#5cb85c;
$background_color_4:#333;



// ======font styles===============

$color_1: #fff;
$color_2: #656565;
$color_3: #337ab7;


$font_family_1: 'Roboto', sans-serif;

$font_size_1:16px;
$font_size_2:14px;
$font_size_3:34px;
$font_size_4:24px;
$font_size_5:20px;
$font_size_6:18px;


$font-bold:bold;
$font-normal:normal;
$font-w500:500;
$font-w300:300;

// ======border===========

$border-style1:1px solid #d2d2d2;



$border-color1:#dad8d8;
$border-color2:#4cae4c;
$border-color3:#fff;



/*================================================
02 - Sass Mixins
==================================================*/


/*@mixin f_style1 {
	color: $color_2;
	font-weight: $font-bold;
	font-size:$font_size_4;
}
*/



/*@mixin shadow-1 {
    -webkit-box-shadow: 2px 3px 0px 0px #ec4500;
    -moz-box-shadow: 2px 3px 0px 0px #1e7619;
    box-shadow: 2px 3px 0px 0px #e93e00;
}
*/



/*================================================
03 - BODY STYLES
==================================================*/

body {
    font-family:$font_family_1 !important;
    font-size: $font_size_2; 
    line-height: 1.5; 
    color: $color_2;
    background: $background_color_1;
}


.header{

    padding-bottom: 5px;
    background: #fff;
    border-bottom: 1px #e3e3e3 solid;

    .contact_logo{
        text-align: center;
        img{
            height: 60px;
            margin: 10px 0px;

        }
    }

    p{
        color: #000;
    }   
}

.contact_sec{
    padding: 50px 0px 0px 0px;

    .contact_area{
        background: $background_color_2;
        border: $border-style1;
        box-shadow: 0 3px 0 #cfcfcf;
        border-radius: 10px;
        padding: 20px;

        h1{
            font-size: $font_size_3;
            font-weight: 600;
        }

        .form-control {
            box-shadow: 0 2px 0 #cfcfcf !important;
        }

        .form-group{

            label{
                font-weight: $font-bold;
                color: $color_2;
            }
        }

        .send_btn {
            width: 100%;
            padding: 10px;
            font-size: $font_size_4;
            border-radius: 7px ;
            background-color: #5cb85c;
            border-color: #4cae4c;
            box-shadow: 0 2px 0 #359735;

 
        }
    }
}

/*================================================
04 - FOOTER
==================================================*/

.footer{
    background: #333;
    text-align: center;
    padding: 16px 0px 0px;
    border-top: 1px solid #dedcdc;
    margin-top: 10px;

    p{
        color: #fff !important;
        font-size: 14px;
    }

    img{
        height: 50px;
        margin-bottom: 10px;
    }

    ul{
        padding: 10px 0px 0px 0px;
        border-top: 1px solid #fff;
        

        .fst{
            border-right: none !important;
        }

        li{
            list-style: none;
            display: inline;
            border-right: 1px solid #fff;
            padding:0px 10px;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            background: none !important;

            &:last-child{
                border:none;
            }

            a{
                color: #fff;
            }
        }
    }
}

/*================================================
05 - MODAL
==================================================*/

.modal{

    .modal-content{

        .modal-header{

            .modal-title{
                font-size: $font_size_5 !important;
                text-transform: capitalize !important;
            }
        }
    }

    .modal-body{

        h3{
            font-size: $font_size_6;
        }
    }
}



/*================================================
06 - MEDIA QUERY
==================================================*/

    $max-break-tab:1199.98px;
    $min-break-tab:991px;

    $max-break-tablet:990px;
    $min-break-tablet:768px;

    $max-break-small: 767px;
    $min-break-small: 576px;

    $max-break-mob: 575px;



@media (min-width:$min-break-tab) and (max-width:$max-break-tab) {

}


@media (min-width:$min-break-tablet) and (max-width:$max-break-tablet) {

}


@media (min-width:$min-break-small) and (max-width:$max-break-small) {
    
}


@media (max-width:$max-break-mob) {

    .header{
        .contact_logo{
            text-align: center !important;

            img{
                height: 55px;
                margin-top: 10px;
            }


        }
    }


    .contact_sec .contact_area .send_btn {
        min-width: 100%;
    }


    .footer{
        img{
            height: 55px;
        }

        p{
            font-size: 13px;
        }

        ul{
            li{
                font-size: 13px;
            }
        }
    }
	
}