@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
  $color_1: #333; 
  $font_family_1: "Roboto", sans-serif;
  body { 
  font-family: $font_family_1; 
  font-size: 15px; 
  line-height: 1.5; 
  color: $color_1; }

