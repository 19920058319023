/*
*******************************************
Template Name: Marriage Tax
Author: Manu joseph
Date:03-06-2021
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for Marriage Tax, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - FOOTER
05 - MEDIA QUERY

============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/
.ADV_SQL_TXH_YG_V1 {
  $background_color_1: #ffffff;
  $background_color_2: #f5ad34;
  $background_color_3: #545454;
  $background_color_4: #f7a900;
  $background_color_5: #545454;
  $background_color_9: #f3f3f3;

  $border_color_1: #5b97ce;
  $border_1: #69a2d7;
  $border_2: #5f5f5f;

  $color_1: #ffffff;
  $color_2: #636363;
  $color_3: #5b97ce;
  $color_4: #beb8b8;
  $color_6: #fce4c7;
  $color_7: #000;


  html {
    scroll-behavior: smooth;
  }

  a {
    cursor: pointer;
  }

  /*================================================
  02 - Body
  ==================================================*/
  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }

  /*================================================
  03 - Header Area Start
  ==================================================*/

  header {
    background-color: #002850;
    padding: 0px 0px 70px 0px;

    .adv_head {
      p {
        margin: 0;
        padding: 0;
        font-size: 16px;
      }
    }

    .logo {
      img {
        width: 75%;
        margin-top: 10px;
        max-width: 100%;
      }
    }

    .top_ad {
      text-align: right;
      padding-bottom: 10px;
    }

    .top_head {
      color: #fff;

      h1 {
        font-weight: 400;
        padding: 20px 0 10px;
        text-align: center;
        font-size: 41px;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
      }

      h5 {
        font-weight: 700;
        text-align: center;
        font-size: 30px;
        margin-bottom: 30px;
      }

      p {
        text-align: center;
        font-size: 16px;
        margin: 0;
      }
    }

    .img_btm {
      h3 {
        color: #fff;
        font-size: 18px;
        line-height: 1.5em;
        margin-top: 17px;
      }
    }

    .left_bg {
      text-align: right;

      img {
        width: 66%;
      }
    }

    .blue_btn {
      color: #ffffff;
      border: solid 1px #fff;
      padding: 10px 25px;
      background: green;
      animation: mymove 2s infinite;
      border-radius: 0px;
      font-size: 34px;
      font-weight: bold;
      margin-top: 20%;

      &:hover {
        color: #dad3d3;
        border: solid 1px #fff;
      }
    }

    .one_bg {
      a {
        // background: url(../img/arrow-li.png) no-repeat;
        background-position: right center;
        top: 5px;
      }

      h3 {
        color: #fff;
        font-size: 22px;
        line-height: 1.5em;
        position: absolute;
        left: 0px;
        bottom: 56px;
      }
    }
  }

  /*================================================
  05 - footer Area Start
  ==================================================*/
  footer {
    background: #ebebeb;
    padding: 20px 0;

    img {
      max-width: 20%;
    }

    p {
      margin: 20px 0;
    }

    ul {
      display: inline;
      list-style: none;
      padding: 0;

      li {
        border-right: 1px solid #000;
        display: inline-block;
        padding: 0 15px;

        &:last-child {
          border-right: none;
        }

        a {
          color: #000;
        }
      }
    }
  }

  @keyframes mymove {
    from {
      background-color: green;
    }
    to {
      background-color: #14bfd5;
    }
  }


  .modal {
    .modal-content {
      .modal-header {
        .modal-title {
          font-weight: 600;
        }

      }

      h5 {
        text-align: left;
        font-size: 17px;
        font-weight: 600;
      }

      .closer {
        background: #ccc;
      }
    }
  }


  /*================================================
  05 - Media Query
  ==================================================*/

  @media (max-width: 576px) {
    .container {
      max-width: 100%;
    }
    .signature-pad {
      width: 400px;
    }

    header {
      .top_head {
        h1 {
          font-size: 35px !important;
          line-height: 1em !important;
          margin-bottom: 17px;
          margin-top: 16px;
        }

        h5 {
          font-size: 29px;
          line-height: 1.3em;
          margin-bottom: 30px;
          font-weight: normal;
        }
      }

      .left_bg {
        img {
          width: 100%;
        }
      }

      .one_bg {
        padding-left: 0px !important;
      }

      .blue_btn {
        width: 100%;
        padding: 6px 22px;
        font-size: 18px;
        margin-top: 18%;
        background-size: 20% !important;
        padding: 10px;
      }

      .img_btm {
        h3 {
          margin-bottom: 25px;
          margin-top: 27px;
        }
      }
    }

    footer {
      img {
        max-width: 40% !important;
      }
    }
  }

  //@media (max-width: 425px){
  //
  //
  //}


  @media (min-width: 577px) and (max-width: 767px) {

    .blue_btn {
      font-size: 21px !important;
    }

    footer {
      img {
        max-width: 35% !important;
      }
    }

  }

  @media (min-width: 768px) and (max-width: 991px) {

    .blue_btn {
      font-size: 28px !important;
    }

  }


  @media (min-width: 991px) and (max-width: 1199px) {

  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }
}
/*========================

==  =====================*/
