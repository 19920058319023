.ADV_SQL_TXH_V1Contact {
  /*
  *******************************************
  Template Name: Smarterliving Guru
  Author: Arun Ravi
  Date: 23-03-2020
  Template By: Vandalay Designs
  Copyright 2020-2021 Vandalay Business Solution

  * This file contains the styling for Smarterliving Guru, this
  is the file you need to edit to change the look of the
  site.

  This files contents are outlined below >>>>

  *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
  *******************************************


  ============================================
  ==== T A B L E   O F   C O N T E N T S =====
  ============================================

  01 - Sass Variables
  02 - Sass Mixins
  03 - BODY
  04 - FOOTER
  05 - MODAL
  06 - MEDIA QUERY

  ============================================
  ============================================

  */


  /*================================================
  01 - Sass Variables
  ==================================================*/


  // =======background colors========

  $background_color_1: #fff;
  $background_color_2: #f7f7f7;
  $background_color_3: #5cb85c;
  $background_color_4: #333;


  // ======font styles===============

  $color_1: #fff;
  $color_2: #656565;
  $color_3: #337ab7;


  $font_family_1: 'Roboto', sans-serif;

  $font_size_1: 16px;
  $font_size_2: 14px;
  $font_size_3: 34px;
  $font_size_4: 24px;
  $font_size_5: 20px;
  $font_size_6: 18px;


  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  $font-w300: 300;

  // ======border===========

  $border-style1: 1px solid #d2d2d2;


  $border-color1: #dad8d8;
  $border-color2: #4cae4c;
  $border-color3: #fff;


  /*================================================
  02 - Sass Mixins
  ==================================================*/


  /*@mixin f_style1 {
      color: $color_2;
      font-weight: $font-bold;
      font-size:$font_size_4;
  }
  */


  /*@mixin shadow-1 {
      -webkit-box-shadow: 2px 3px 0px 0px #ec4500;
      -moz-box-shadow: 2px 3px 0px 0px #1e7619;
      box-shadow: 2px 3px 0px 0px #e93e00;
  }
  */


  /*================================================
  03 - BODY STYLES
  ==================================================*/

  body {
    font-family: $font_family_1 !important;
    font-size: $font_size_1 !important;
    line-height: 1.5;
    color: $color_2;
    background: $background_color_1;
  }


  .header {
    padding: 5px 0px;
    background: #fff;
    border-bottom: 1px #e3e3e3 solid;

    .contact_logo {
      img {
        height: 60px;
      }
    }

    p {
      color: #000;
    }
  }

  .contact_sec {
    padding: 50px 0px 20px 0px;

    .contact_area {
      background: $background_color_2;
      border: $border-style1;
      box-shadow: 0 3px 0 #cfcfcf;
      border-radius: 10px;
      padding: 20px;

      h1 {
        font-size: $font_size_3;
        font-weight: $font-w300;
      }

      .form-control {
        box-shadow: 0 2px 0 #cfcfcf !important;
      }

      .form-group {

        label {
          font-weight: $font-bold;
          color: $color_2;
        }
      }

      .send_btn {
        min-width: 60%;
        padding: 15px;
        font-size: $font_size_4;
        border-radius: 7px;
        background-color: #1ade64;


      }
    }

    .addr {
      p {
        margin: 0;
      }
    }
  }

  /*================================================
  04 - FOOTER
  ==================================================*/

  .footer {
    background: #333;
    color: $color_1;
    padding: 20px 0 10px;
    border-top: 1px solid #dedcdc;

    img {
      height: 60px;
    }

    p {
      font-size: 14px;
      margin-bottom: 7px;
      color: $color_1;
    }

    a {
      text-decoration: none !important;
    }

    .end_footer {
      border-top: 1px solid $color_1;
      padding: 0;

      ul {
        padding: 0;
        margin-top: 15px;

        li {
          display: inline;
          border-right: 1px solid $color_1;
          padding: 0 20px;
          list-style: none;
          cursor: pointer;

          &:last-child {
            border-right: none;
          }

          a {
            color: $color_1;
          }
        }
      }
    }
  }

  /*================================================
  05 - MODAL
  ==================================================*/

  .modal {
    background: #0000008c !important;
    .modal-content {

      .modal-header {

        .modal-title {
          font-size: $font_size_5 !important;
          text-transform: capitalize !important;
        }
      }
    }

    .modal-body {

      h3 {
        font-size: $font_size_6;
      }
    }
  }


  /*================================================
  06 - MEDIA QUERY
  ==================================================*/

  $max-break-tab: 1199.98px;
  $min-break-tab: 991px;

  $max-break-tablet: 990px;
  $min-break-tablet: 768px;

  $max-break-small: 767px;
  $min-break-small: 576px;

  $max-break-mob: 575px;


  @media (min-width: $min-break-tab) and (max-width: $max-break-tab) {

  }


  @media (min-width: $min-break-tablet) and (max-width: $max-break-tablet) {

  }


  @media (min-width: $min-break-small) and (max-width: $max-break-small) {

  }


  @media (max-width: $max-break-mob) {

    .header {
      .contact_logo {
        text-align: center !important;

        img {
          height: 55px;
          margin-top: 10px;
        }


      }
    }


    .contact_sec .contact_area .send_btn {
      min-width: 100%;
    }


    .footer {
      img {
        height: 55px;
      }

      p {
        font-size: 13px;
      }

      ul {
        li {
          font-size: 13px;
        }
      }
    }

  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .error_msg {
    color: #ffffff;
    width: 100%;
    float: left;
    background: #F00;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 0px;
    text-align: center;
  }
}