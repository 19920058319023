/*
*******************************************
Template Name:SavvySam
Author: Anush
Date:23-03-2023
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the SavvySam, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
******************************************* */

/*================================================
01 - Sass Variables
==================================================*/
.SVS_GLI_ADV1 {
	$color_11: #ffffff;
	$background-color_1: #f9f9fa;

	/*================================================
	03 - BODY STYLES
	==================================================*/
	html {
		scroll-behavior: smooth;
	}

	body {
		margin: 0;
		padding: 0;
		background-color: $background-color_1;
		font-family: 'Roboto', sans-serif !important;
	}

	a {
		cursor: pointer;
		text-decoration: none;
	}

	// ======CUSTOM ELEMENTS========
	.webPage {
		header {
			background: #fff;
			padding: 14px 40px;
			-webkit-box-shadow: 0px 4px 5px -2px rgba(173, 173, 173, 0.36);
			-moz-box-shadow: 0px 4px 5px -2px rgba(173, 173, 173, 0.36);
			box-shadow: 0px 4px 5px -2px rgba(173, 173, 173, 0.36);

			.click-here {
				color: #fff;
				background: #1c5b99;
				border: 1px #14487c;
				border-bottom: 4px solid #12477c;
			}

			.img-responsive {
				max-width: 100%;
				height: 37px !important;
			}
		}

		section {
			padding: 30px 0;

			.container {
				max-width: 800px;
				margin: 0 auto;

				.content {
					padding: 1px 20px;

					.big-font {
						font-size: 43px;
						font-weight: 500;
						line-height: 50px;
						padding-top: 10px;
						color: #212529;
					}

					.sub_title {
						color: #6b6b6b;
						font-size: 30px;
					}

					.image-responsive {
						max-width: 100%;
					}

					p {
						color: #212529;
						font-size: 20px;
					}

					.yellow_link {
						span {
							background-color: #ff0;

							a {
								color: #0d6efd;
								text-decoration: underline;
							}
						}
					}

					.btn_sec {
						a {
							text-decoration: none !important;
							color: unset !important;
						}

						.btn-custom {
							color: $color_11 !important;
							font-weight: 400;
							font-size: 20px !important;
							width: 40%;
							margin-top: 10px;
							margin-right: 1px;
							margin-left: 1px;

							.fa-custom {
								margin-right: 15px;
							}
						}

						.btn-red {
							background: #cc3535 !important;
							border-bottom: 3px solid #940b0b !important;

							&:hover {
								background: #9e1e1e !important;
								border-bottom: 3px solid #6d0404 !important;
							}
						}

						.btn-green {
							background: #008000 !important;
							border-bottom: 3px solid #026202 !important;

							&:hover {
								background: #016d01 !important;
								border-bottom: 3px solid #004e00 !important;
							}
						}
					}

					.age_btn {
						.age_btns {
							border-radius: 0;
							width: auto;
							text-align: center;

							&:hover {
								box-shadow: 0 0 0 5px #f29129;
							}

							img {
								height: 70px;
								margin-bottom: 5px;
							}

							span {
								color: #f29129;
								font-size: 20px;
							}
						}

						.main_btn {
							color: #fff;
							background-color: green;
							font-size: 23px;
							font-weight: 500;
							text-align: center;
							padding: 10px;
							border-radius: 5px;
							transition: .5s;
							width: 60%;
							margin-top: 20px;
						}

						.pulse {
							animation-name: pulse;
							-webkit-animation-name: pulse;
							animation-duration: 1.5s;
							-webkit-animation-duration: 1.5s;
							animation-iteration-count: infinite;
							-webkit-animation-iteration-count: infinite;
						}
					}

					.sidebar {
						display: inline-block;
						vertical-align: top;
					}

					.fixedsticky {
						top: 25px;
						position: sticky;
					}

				}
			}
		}

		@keyframes pulse {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.1);

			}

			100% {
				transform: scale(1);
			}
		}


		footer {
			padding: 12px 0;
			-webkit-box-shadow: 0px -5px 26px -2px rgba(173, 173, 173, 0.36);
			-moz-box-shadow: 0px -5px 26px -2px rgba(173, 173, 173, 0.36);
			box-shadow: 0px -5px 26px -2px rgba(173, 173, 173, 0.36);

			.img-responsive {
				max-width: 100%;
				height: 32px !important;
			}

			.small {
				font-size: 13px;
				margin: 5px;
			}

			.links {
				text-align: center;
				margin: 5px auto;
				padding: 0;

				li {
					margin: 0 3px;
					padding: 0;
					display: inline;
					vertical-align: baseline;
					font-size: 13px !important;
					color: #898c8d;

					a {
						color: #898c8d !important;
						cursor: pointer;
						text-decoration: none;
					}
				}
			}
		}

		////// MODAL DESIGN //////

		.modal-content {
			.modal-title {
				font-size: 22px !important;
			}

			.ppcontent {
				h3 {
					font-size: 20px !important;
				}
			}
		}
	}

	//// MEDIA QUERY ///////

	@media (min-width: 768px) and (max-width: 990px) {
		.main_btn {
			width: 60% !important;
			margin-top: 20px !important;
		}

		.tabwidth {
			width: 60% !important;
		}
	}

	@media (min-width: 578px) and (max-width: 767px) {
		.main_btn {
			width: 80% !important;
		}
	}

	@media (max-width: 697px) {
		header {
			padding: 0 !important;
		}
	}

	@media (max-width: 578px) {
		.big-font {
			font-size: 28px !important;
			line-height: normal !important;
		}

		.sub_title {
			font-size: 23px !important;
		}

		.brand-logo {
			text-align: center;
		}

		p {
			color: #212529;
			font-size: 16px !important;
		}

		.mbl-cen {
			color: $color_11 !important;
			text-align: end !important;

			a {
				color: $color_11;
				text-decoration: none;
				font-size: 15px !important;
			}
		}

		.age_btns {
			width: 30%;
			padding: 0;
			margin: 3px;
		}

		.main_btn {
			width: 100% !important;
			margin-top: 10px !important;
			font-size: 16px !important;
		}

		.small {
			font-size: 13px !important;
		}

	}

	@media (min-width: 578px) {
		.mbl-cen {
			color: $color_11 !important;
			text-align: end !important;
		}
	}

	@media (min-width: 672px) {
		.content {
			padding: 32px 48px;
		}
	}

	@media screen and (max-width: 767px) {
		.img-responsive {
			height: 32px;
		}
	}

	@media screen and (max-width: 560px) {
		.btn-custom {
			width: 50%;
		}
	}

	@media screen and (min-width: 1200px) {
		.container {
			max-width: 1140px !important;
		}

		.container-fluid {
			max-width: 1080px !important;
		}
	}
	
	.hide {
		display: none;
	  }
	
	  .show {
		display: block;
	  }
	  .terms-list{
		padding-left: 32px !important;
	  }
	  .margin-12{ 
		padding-left:12px;
		padding-right: 12px;
	}
	.txt_underline{
		color: var(--bs-link-color);
		text-decoration: underline;
	}
	.row_margin{
		margin-left: -12px;
		margin-right: -12px;
	}
}
/*========================

==  =====================*/